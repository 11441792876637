<template>
  <div>
    <vm-table
      ref="vmTable"
      v-loading="loading"
      :filter.sync="search"
      url="deviceGateway"
      @selection-change="handleSelectionChange"
      @clear="clearDate">
      <template slot="adSearch">
        <vm-search :label="$t('maintWorkOrder.filter')">
          <el-input v-model.trim="search.filter" :placeholder="$t('common.search')" clearable></el-input>
        </vm-search>
        <vm-search :label="$t('deviceGateway.bindState')">
          <el-select
            v-model.trim="search.bindState"
            :placeholder="$t('common.pleaseSelect')"
            :style="{ width: $l('100px', '100px') }"
            clearable>
            <el-option
              v-for="item in bindStateOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </vm-search>
        <vm-search :label="$t('deviceGateway.linkStatus')">
          <el-select
            v-model="search.connectionStatus"
            :placeholder="$t('common.pleaseSelect')"
            :style="{ width: $l('150px', '100px') }"
            clearable>
            <el-option
              v-for="item in connectionStatusOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </vm-search>
        <vm-search :label="$t('deviceGateway.onlineState')">
          <el-select
            v-model.trim="search.onlineState"
            :placeholder="$t('common.pleaseSelect')"
            :style="{ width: $l('100px', '100px') }"
            clearable>
            <el-option
              v-for="item in onlineStateOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </vm-search>
        <vm-search :label="$t('deviceGateway.inNetState')">
          <el-select
            v-model.trim="search.inNetState"
            :placeholder="$t('common.pleaseSelect')"
            :style="{ width: $l('120px', '100px') }"
            clearable>
            <el-option
              v-for="item in inNetStateOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </vm-search>
        <vm-search :label="$t('deviceGateway.blackStatus')">
          <el-select
            v-model.trim="search.blackState"
            :placeholder="$t('common.pleaseSelect')"
            :style="{ width: $l('100px', '100px') }"
            clearable>
            <el-option
              v-for="item in blackStateOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </vm-search>
        <vm-search :label="$t('deviceGateway.isSendAlarmSms')">
          <el-select
            v-model.trim="search.isSendAlarmSms"
            :placeholder="$t('common.pleaseSelect')"
            :style="{ width: $l('100px', '100px') }"
            clearable>
            <el-option
              v-for="item in isSendAlarmSmsOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </vm-search>
        <vm-search :label="$t('deviceGateway.isInsertJxOrder')">
          <el-select
            v-model.trim="search.isInsertJxOrder"
            :placeholder="$t('common.pleaseSelect')"
            :style="{ width: $l('100px', '100px') }"
            clearable>
            <el-option
              v-for="item in isSendAlarmSmsOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </vm-search>
        <vm-search :label="$t('deviceGateway.onlineUpdateTime')">
          <el-date-picker
            v-model="onlineUpdateTime"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetimerange"
            :range-separator="$t('common.to')"
            :start-placeholder="$t('common.startDate')"
            :end-placeholder="$t('common.endDate')"
            clearable
            @change="handleDateOnline">
          </el-date-picker>
        </vm-search>
        <vm-search :label="$t('deviceGateway.updateTime')">
          <el-date-picker
            v-model="lastUpdateTime"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetimerange"
            :range-separator="$t('common.to')"
            :start-placeholder="$t('common.startDate')"
            :end-placeholder="$t('common.endDate')"
            clearable
            @change="handleDateUpdateTime">
          </el-date-picker>
        </vm-search>
        <vm-search :label="$t('deviceGateway.expirationTime')">
          <el-date-picker
            v-model="simExpireTime"
            value-format="yyyy-MM-dd"
            type="daterange"
            :range-separator="$t('common.to')"
            :start-placeholder="$t('common.startDate')"
            :end-placeholder="$t('common.endDate')"
            clearable
            @change="handleDateSimExpire">
          </el-date-picker>
        </vm-search>
      </template>
      <template slot="toolbar">
        <el-button class="greenBtn" @click="downloadTemplate()">
          {{ $t("deviceGateway.downloadingTemplate") }}
        </el-button>
        <el-upload
          :action="uploadUrl"
          :before-upload="beforeUpload"
          :on-success="onUploadSuccess"
          :on-error="onUploadError"
          :on-exceed="onUploadExceed"
          :show-file-list="false"
          :headers="uploadHeaders"
          accept=".xlsx"
          :limit="1"
          :disabled="importLoading"
          style="display:inline-block;margin-left: 5px;">
          <el-button :loading="importLoading">
            {{ $t("deviceGateway.import") }}
          </el-button>
        </el-upload>
        <el-button style="margin-left: 10px;" @click="$refs.setBlackState.open()" @save-success="getList(-1)">
          {{ $t("deviceGateway.blacklist") }}
        </el-button>
        <el-button style="margin-left: 10px;" @click="$refs.editAlarm.open()" @save-success="getList(-1)">
          {{ $t("deviceGateway.isSendAlarmSms") }}
        </el-button>
        <el-button style="margin-left: 10px;" @click="$refs.editJx.open()" @save-success="getList(-1)">
          {{ $t("deviceGateway.isInsertJxOrder") }}
        </el-button>
        <el-button @click="$refs.devicePushCount.open()">
          {{ $t("deviceGateway.pushStatistics") }}
        </el-button>
        <el-button v-if="$i18n.isCn && simCardAuth && false" @click="handleFlowcard">
          {{ $t("device.simCard") }}
        </el-button>
        <el-button v-if="$i18n.isCn && upgradeAuth" @click="handleUpdate">
          {{ $t("device.upgrade") }}
        </el-button>
        <el-button @click="handleStatus">
          {{ $t("device.status") }}
        </el-button>
        <el-button @click="$refs.setSupportProtocol.open()" @save-success="getList(-1)">
          {{ $t("deviceGateway.supportProtocol") }}
        </el-button>
        <el-button @click="handleBind">
          {{ $t("deviceGateway.updateBindingStatus") }}
        </el-button>
        <el-button @click="handleOnline">
          {{ $t("deviceGateway.updateOnlineStatus") }}
        </el-button>
        <el-button style="margin-left: 10px;" @click="handleRoute" @save-success="getList(-1)">
          {{ $t("deviceGateway.gatewayRouteSet") }}
        </el-button>
      </template>
      <el-table-column type="selection" width="50" align="center">
      </el-table-column>
      <el-table-column prop="dtuCode" :label="$t('deviceGateway.dtuCode')" width="120" align="center">
        <template slot-scope="scope">
          <a v-if="scope.row.bindState" @click="$refs.deviceNode.open(scope.row.dtuCode)">
            {{ scope.row.dtuCode }}
          </a>
          <div v-else>{{ scope.row.dtuCode }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="onlineState" :label="$t('deviceGateway.dtuStatus')" width="100" align="center">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.onlineState === 1" type="success">
            {{ $t("deviceGateway.dtuOnline") }}
          </el-tag>
          <el-tag v-if="scope.row.onlineState === 0" type="info">
            {{ $t("deviceGateway.dtuOffline") }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column
        prop="supportProtocol"
        :label="$t('deviceGateway.supportProtocol')"
        width="100"
        align="center"></el-table-column>
      <el-table-column
        prop="onlineUpdateTime"
        :label="$t('deviceGateway.onlineUpdateTime')"
        width="140"
        align="center"></el-table-column>
      <el-table-column prop="blackState" :label="$t('deviceGateway.blackStatus')" width="80" align="center">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.blackState === true" type="danger">
            {{ $t("deviceGateway.blackYes") }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column :label="$t('deviceGateway.isSendAlarmSms')" width="80" align="center" prop="isSendAlarmSms">
        <template slot-scope="scope">
          <el-tag :type="scope.row.isSendAlarmSms === 1 ? 'success' : 'info'">
            {{ scope.row.isSendAlarmSms === 1 ? $t("deviceGateway.turnOn") : $t("deviceGateway.closed") }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column :label="$t('deviceGateway.isInsertJxOrder')" width="110" align="center" prop="isInsertJxOrder">
        <template slot-scope="scope">
          <el-tag :type="scope.row.isInsertJxOrder === 1 ? 'success' : 'info'">
            {{ scope.row.isInsertJxOrder === 1 ? $t("deviceGateway.turnOn") : $t("deviceGateway.closed") }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column
        prop="deviceModel"
        :label="$t('deviceGateway.deviceModel')"
        align="center"
        width="100"></el-table-column>
      <el-table-column
        prop="signalType"
        :label="$t('deviceGateway.signalType')"
        align="center"
        width="100"></el-table-column>
      <el-table-column
        prop="simCode"
        :label="$t('deviceGateway.simCode')"
        width="165"
        align="center"></el-table-column>
      <el-table-column
        prop="simICCID"
        :label="$t('deviceGateway.simICCID')"
        width="165"
        align="center"></el-table-column>
      <el-table-column
        prop="simExpireDate"
        :label="$t('deviceGateway.expirationTime')"
        width="120"
        align="center">
        <template v-if="scope.row.simExpireDate" slot-scope="scope">
          {{ scope.row.simExpireDate.substr(0, 10) }}
        </template>
      </el-table-column>
      <el-table-column
        prop="softVer"
        :label="$t('deviceGateway.softVer')"
        align="center"
        width="100"></el-table-column>
      <el-table-column
        prop="hardVer"
        :label="$t('deviceGateway.hardVer')"
        align="center"
        width="100"></el-table-column>
      <el-table-column
        prop="signalStrength"
        :label="$t('deviceGateway.signalStrength')"
        align="center"
        width="100"></el-table-column>
      <el-table-column
        prop="temperature"
        :label="$t('deviceGateway.temperature')"
        align="center"
        width="100"></el-table-column>
      <el-table-column
        prop="electricityQuantity"
        :label="$t('deviceGateway.devicePower')"
        align="center"
        width="100"></el-table-column>
      <el-table-column
        prop="connectionStatus"
        :label="$t('deviceGateway.linkStatus')"
        width="110"
        align="center">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.connectionStatus === 1" type="success">
            {{ $t("deviceGateway.communicationNormal") }}
          </el-tag>
          <el-tag v-if="scope.row.connectionStatus === 0" type="warning">
            {{ $t("deviceGateway.communicationFailed") }}
          </el-tag>
          <el-tag v-if="scope.row.connectionStatus === -1" type="danger">
            {{ $t("deviceGateway.invalid") }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column :label="$t('deviceGateway.inNetState')" width="100" align="center" prop="inNetState">
        <template slot-scope="scope">
          <el-tag :type="scope.row.inNetState === true ? 'success' : 'info'">
            {{ scope.row.inNetState === true ? $t("elevator.joined") : $t("elevator.notJoined") }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column
        prop="lastUpdateTime"
        :label="$t('deviceGateway.updateTime')"
        width="140"
        align="center"></el-table-column>
      <el-table-column
        prop="accessTime"
        :label="$t('deviceGateway.accessTime')"
        width="140"
        align="center"></el-table-column>
      <el-table-column fixed="right" :label="$t('elevatorNetApply.operate')" align="center" width="120">
        <template slot-scope="scope">
          <el-button type="text" style="color: #00C291;" @click="$refs.logList.open(scope.row.dtuCode)">
            {{ $t("device.record") }}
          </el-button>
          <el-button v-if="false" type="text" @click="$refs.editPage.open(scope.row.id)">
            {{ $t("elevatorNetApply.edit") }}
          </el-button>
          <el-button type="text" class="operateDelBtn" @click="handleDelete(scope.row)">
            {{ $t("elevatorNetApply.delete") }}
          </el-button>
          <el-button v-if="false" type="text" @click="$refs.intercomSet.open(scope.row.dtuCode, '01')">
            {{
              $t("device.intercom") }}
          </el-button>
        </template>
      </el-table-column>
    </vm-table>
    <set-black-state ref="setBlackState" :multi-select="multiSelect" @save-success="getList(-1)"></set-black-state>
    <set-routing ref="setRouting" :multi-select="multiSelect" @save-success="getList(-1)"></set-routing>
    <set-support-protocol
      ref="setSupportProtocol"
      :multi-select="multiSelect"
      @save-success="getList(-1)"></set-support-protocol>
    <edit-page ref="editPage" @save-success="getList(-1)"></edit-page>
    <device-node ref="deviceNode"></device-node>
    <device-push-count ref="devicePushCount"></device-push-count>
    <device-setting ref="deviceSetting"></device-setting>
    <intercom-set ref="intercomSet"></intercom-set>
    <device-log-list ref="logList"></device-log-list>
    <set-alarm ref="editAlarm" :multi-select="multiSelect" @save-success="getList(-1)"></set-alarm>
    <set-jx ref="editJx" :multi-select="multiSelect" @save-success="getList(-1)"></set-jx>
  </div>
</template>
<script>
  import SetJx from "./SetJx";
  import SetAlarm from "./SetAlarm";
  import auth from "@/util/auth";
  import EditPage from "./DeviceGatewayEdit";
  import SetBlackState from "@/views/deviceGateway/SetBlackState";
  import DeviceNode from "@/views/deviceGateway/DeviceNode";
  import DevicePushCount from "@/views/deviceGateway/DevicePushCount";
  import DeviceSetting from "@/views/deviceGateway/DeviceSetting";
  import SetSupportProtocol from "@/views/deviceGateway/SetSupportProtocol";
  import IntercomSet from "@/views/device/Intercom";
  import DeviceLogList from "@/views/device/DeviceLogList.vue";
  import SetRouting from "@/views/deviceGateway/SetRouting";

  const moduleName = "deviceGateway";
  export default {
    components: {
      SetRouting,SetAlarm,SetJx,
      DeviceLogList, SetSupportProtocol, EditPage, SetBlackState, DeviceNode, DevicePushCount, DeviceSetting, IntercomSet },
    data() {
      return {
        simCardAuth: this.$auth(803),
        upgradeAuth: this.$auth(802),
        tableData: [],
        multiSelect: [],
        total: 0,
        currentPage: 1,
        adSearch: false,
        importLoading: false,
        loading: true,
        uploadUrl: window.config.SERVER_URL + "deviceGateway/import",
        uploadHeaders: { Authorization: auth.getToken() },
        lastUpdateTime: [],
        simExpireTime: [],
        onlineUpdateTime: [],
        search: {
          filter: "",
          blackState: "",
          connectionStatus: "",
          inNetState: "",
          onlineState: "",
          lastUpdate_beginTime: "",
          lastUpdate_endTime: "",
          simExpire_beginTime: "",
          simExpire_endTime: "",
          onlineUpdate_beginTime: "",
          onlineUpdate_endTime: "",
          isInsertJxOrder: "",
          isSendAlarmSms: "",
        },
        isSendAlarmSmsOptions:[
          { value: 0, label: this.$t("deviceGateway.closed") },
          { value: 1, label: this.$t("deviceGateway.turnOn") },
        ],
        bindStateOptions: [
          { value: 0, label: this.$t("deviceGateway.bound") },
          { value: 1, label: this.$t("deviceGateway.unbound") },
        ],
        connectionStatusOptions: [
          { value: 0, label: this.$t("deviceGateway.communicationFailed") },
          { value: 1, label: this.$t("deviceGateway.communicationNormal") },
          { value: -1, label: this.$t("deviceGateway.invalid") },
        ],
        inNetStateOptions: [
          { value: true, label: this.$t("elevator.joined") },
          { value: false, label: this.$t("elevator.notJoined") },
        ],
        onlineStateOptions: [
          { value: 0, label: this.$t("deviceGateway.dtuOffline") },
          { value: 1, label: this.$t("deviceGateway.dtuOnline") },
        ],
        blackStateOptions: [
          { value: true, label: this.$t("deviceGateway.yes") },
          { value: false, label: this.$t("deviceGateway.no") },
        ],
      };
    },
    mounted() {
      this.getList(1);
    },
    methods: {
      clearDate() {
        this.onlineUpdateTime = [];
        this.lastUpdateTime = [];
        this.simExpireTime = [];
      },
      handleDateOnline() {
        if (this.onlineUpdateTime != null) {
          this.search.onlineUpdate_beginTime = this.onlineUpdateTime[0];
          this.search.onlineUpdate_endTime = this.onlineUpdateTime[1];
        } else {
          this.search.onlineUpdate_beginTime = "";
          this.search.onlineUpdate_endTime = "";
        }
      },
      handleDateUpdateTime() {
        if (this.lastUpdateTime != null) {
          this.search.lastUpdate_beginTime = this.lastUpdateTime[0];
          this.search.lastUpdate_endTime = this.lastUpdateTime[1];
        } else {
          this.search.lastUpdate_beginTime = "";
          this.search.lastUpdate_endTime = "";
        }
      },
      handleDateSimExpire() {
        if (this.simExpireTime != null) {
          this.search.simExpire_beginTime = this.simExpireTime[0];
          this.search.simExpire_endTime = this.simExpireTime[1];
        } else {
          this.search.simExpire_beginTime = "";
          this.search.simExpire_endTime = "";
        }
      },
      downloadTemplate() {
        if (this.$i18n.isCn) {
          window.open("/file/电梯Sim卡信息导入模板.xlsx", "_self");
        } else {
          window.open("/file/ElevatorSimCardTemplate.xlsx", "_self");
        }
      },
      getList(pageNo) {
        this.loading = false;
        this.$refs.vmTable.getList(pageNo);
      },
      handleSelectionChange(val) {
        this.multiSelect = val;
      },
      multiSelectSure() {
        this.$emit("multi-select", this.multiSelect);
      },
      handleDelete(row) {
        this.$confirm(this.$t("common.delete") + " " + this.$t("elevator.dtuCode") + " " + row.dtuCode + "," + this.$t("common.isDelete") + "?", this.$t("common.tips"), { type: "warning" }).then(() => {
          this.$http.delete("deviceGateway", row.id).then(() => {
            this.getList(-1);
            this.$message.success(this.$t("common.tip.deleteSuccess"));
          });
        });
      },
      onUploadSuccess() {
        this.importLoading = false;
        this.$message.success(this.$t("elevatorNetApply.importSuccess"));
        this.getList(1);
      },
      beforeUpload(file) {
        let fileName = file.name;
        let index = fileName.lastIndexOf(".");
        let lastName = fileName.substring(index, fileName.length);
        if (lastName.toLowerCase() !== ".xlsx") {
          this.$message.error(this.$t("elevatorNetApply.uploadType"));
          return false;
        }
        this.importLoading = true;
        return true;
      },
      onUploadError(err) {
        this.importLoading = false;
        if (!err.message) {
          this.$message.error(this.$t("elevatorNetApply.importFailure"));
        } else {
          console.log(JSON.parse(err.message).message);
          console.log(err.message);
          this.$message.error(this.$t("elevatorNetApply.importFailure") + ": " + JSON.parse(err.message).message);
        }
      },
      onUploadExceed() {
        this.$message.error(this.$t("elevatorNetApply.importOnly"));
      },
      handleFlowcard() {
        if (this.multiSelect && this.multiSelect.length > 0) {
          if (this.multiSelect.length > 1) {
            this.$message.error(this.$t("deviceGateway.deviceCard"));
            return;
          }
          let row = this.multiSelect[0];
          if (!row.simICCID || row.simICCID === "" || row.simICCID === "0") {
            this.$message.error(this.$t("device.iccidIsEmpty"));
          } else {
            window.open("#/simCard/" + row.simICCID);
          }
        } else {
          this.$message.error(this.$t("deviceGateway.trafficCard"));
        }
      },
      handleUpdate() {
        if (this.multiSelect && this.multiSelect.length > 0) {
          if (this.multiSelect.length > 1) {
            this.$message.error(this.$t("deviceGateway.selectOne"));
            return;
          }
          let row = this.multiSelect[0];
          this.$confirm(this.$t("device.upgradeConfirm"), this.$t("common.tips"), {
            confirmButtonText: this.$t("common.confirm"),
            cancelButtonText: this.$t("common.cancel"),
            type: "warning",
          }).then(() => {
            this.$api.getData("devices/upgrade/" + row.dtuCode).then(() => {
              this.$message.success(this.$t("common.tip.operationSuccess"));
            }).catch((error) => {
              this.$message.error(this.$t("device.upgrade") + this.$t("common.tip.operationFailure") + "，" + error.response.data.message);
            });
          });
        } else {
          this.$message.error(this.$t("deviceGateway.trafficCard"));
        }
      },
      handleStatus() {
        if (this.multiSelect && this.multiSelect.length > 0) {
          if (this.multiSelect.length > 1) {
            this.$message.error(this.$t("deviceGateway.deviceStatus"));
            return;
          }
          let row = this.multiSelect[0];
          this.$refs.deviceSetting.open(row.dtuCode);
        } else {
          this.$message.error(this.$t("deviceGateway.checkDevice"));
        }
      },
      handleBind() {
        this.$http.post("deviceGateway/bind").then(() => {
          this.$message.success(this.$t("common.tip.operationSuccess"));
        }).catch((error) => {
          this.$message.error(this.$t("device.upgrade") + this.$t("common.tip.operationFailure") + "，" + error.response.data.message);
        });
      },
      handleOnline() {
        this.$http.post("deviceGateway/syncRedisDtuInfo").then(() => {
          this.$message.success(this.$t("common.tip.operationSuccess"));
        }).catch((error) => {
          this.$message.error(this.$t("device.upgrade") + this.$t("common.tip.operationFailure") + "，" + error.response.data.message);
        });
      },
      handleRoute() {
        if (this.multiSelect && this.multiSelect.length > 0) {
          if (this.multiSelect.length > 1) {
            this.$message.error(this.$t("deviceGateway.deviceStatus"));
            return;
          }
          let row = this.multiSelect[0];
          this.$refs.setRouting.open(row.dtuCode);
        } else {
          //this.$message.error(this.$t("deviceGateway.checkDevice"));
          this.$message.error("请先在列表选择需要设置的网关设备");
        }
      },
    },
  };
</script>
<style lang="scss" scoped></style>
