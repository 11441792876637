<template>
  <el-dialog
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    :title="$t('deviceGateway.edit')"
    width="500px"
    top="3vh">
    <div>
      <span style="margin-left: 50px">{{$t("deviceGateway.isInsertJxOrder")}}</span> :
      <el-radio-group v-model="isInsertJxOrder" style="margin-left: 20px">
        <el-radio :label="0">{{$t("deviceGateway.closed")}}</el-radio>
        <el-radio :label="1">{{$t("deviceGateway.turnOn")}}</el-radio>
      </el-radio-group>
    </div>
    <span slot="footer">
      <el-button @click="dialogVisible = false">{{$t("elevatorNetApply.cancel")}}</el-button>
      <el-button
        :loading="submitLoading"
        type="primary"
        @click="handleSubmit">{{$t("elevatorNetApply.save")}}</el-button>
    </span>
  </el-dialog>
</template>

<script>
  export default {
    props: {
      multiSelect: {
        type: Array,
      },
    },
    data() {
      return {
        isInsertJxOrder: 1,
        dialogVisible: false,
        submitLoading: false,
      };
    },
    methods: {
      open() {
        this.dialogVisible = true;
      },
      handleSubmit() {
        let deviceIds = [];
        if (this.multiSelect && this.multiSelect.length > 0) {
          for (let i = 0; i < this.multiSelect.length; i++) {
            let item = this.multiSelect[i];
            deviceIds.push(item.id);
          }
          let params = {
            deviceIds: deviceIds,
            isInsertJxOrder: this.isInsertJxOrder,
          };
          this.$http.save("deviceGateway/setJx", params).then(() => {
            this.dialogVisible = false;
            this.submitLoading = false;
            this.$emit("save-success");
            this.$message.success(this.$t("common.tip.settingSuccess"));
          }).catch(error => {
            this.submitLoading = false;
            this.$message.error(this.$t("common.tip.settingError") + "," + error);
          });
        } else {
          this.$message.error(this.$t("deviceGateway.selectDevice"));
        }
      },
    },
  };
</script>

<style lang="scss" scoped>

</style>
